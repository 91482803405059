'use client'

import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Icon } from '../icon';
import classNames from 'classnames';
import useDirtyGlobal from '@hooks/use-dirty-global';
import Button from '../button';
import ReactFocusLock from 'react-focus-lock';

export type ModalPropType = {
  children: ReactNode;
  isOpen: boolean;
  title?: string;
  handleCloseModal: () => void | Promise<void>;
  className?: string;
  contentClassName?: string;
  disableEsc?: boolean;
  checkCloseModal?: boolean;
  formName?: string;
  isLoading?: boolean;
};

const Modal = ({
  isOpen = false,
  title,
  handleCloseModal,
  children,
  className,
  contentClassName,
  disableEsc,
  checkCloseModal,
  formName,
  isLoading = false,
}: ModalPropType) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const { checkedCallback, dirtyModal } = useDirtyGlobal(
    {
      onCloseModal: async () => {
        await handleCloseModal();
      },
    },
    formName
  );

  useEffect(() => {
    if (!isOpen) return;

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && !disableEsc && !isLoading && isOpen) {
        checkCloseModal ? checkedCallback.onCloseModal() : void handleCloseModal();
      }
    };

    const handleFocusEvent = () => {
      if (isOpen) {
        window.scrollTo(scrollPosition.x, scrollPosition.y);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('focusin', handleFocusEvent);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('focusin', handleFocusEvent);
    };
  }, [handleCloseModal, disableEsc, checkCloseModal, checkedCallback, isLoading, isOpen, scrollPosition]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      html, body {
        overflow: hidden;
      }
    `;
    document.head.appendChild(style);
    setScrollPosition({ x: window.scrollX, y: window.scrollY });

    return () => {
      document.head.removeChild(style);
    };
  }, [isOpen]);

  const modalContent = (
    <>
      <ReactFocusLock persistentFocus={true} autoFocus={false}>
        <div className="relative z-20" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className='fixed inset-0 z-10 w-full overflow-y-auto'>
            <div className="flex min-h-full justify-center overflow-hidden text-center items-center p-4">
              <div
                aria-modal="true"
                className={classNames(
                  'relative animate-show-down transform rounded bg-gray-background text-left shadow-modal transition-all sm:my-8 w-full h-full sm:max-w-lg',
                  className
                )}
              >
                {title && (
                  <div className="bg-white lg:px-[30px] py-3.5 px-6 px-2 flex justify-between border-b border-gray-dialog-line">
                    <div className="text-blue-primary text-lg not-italic font-light leading-8 tracking-[-0.54px] truncate" title={title}>
                      {title}
                    </div>
                    <Button
                      className="default p-1 border-none"
                      size="sm"
                      disabled={isLoading}
                      onClick={
                        checkCloseModal
                          ? checkedCallback.onCloseModal
                          : async () => {
                            await handleCloseModal();
                          }
                      }
                    >
                      <Icon variant="close" svgClassName={{ fill: 'fill-black' }} />
                    </Button>
                  </div>
                )}

                <div className={classNames('p-4 sm:p-[30px]', contentClassName)}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </ReactFocusLock>
      {checkCloseModal && dirtyModal}
    </>
  );

  return isOpen ? createPortal(modalContent, document.body) : null;
};

export default Modal;
