import { FC } from 'react';
import Dialog from '@components/dialog';

interface IDirtyModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  onYesClick?: () => void;
  onNoClick?: () => void;
}

const DirtyModal: FC<IDirtyModalProps> = ({ openModal, setOpenModal, onNoClick, onYesClick }) => {
  return (
    <Dialog
      title="Cancel"
      questionMessage="The changes have not been saved. Do you want to continue?"
      isOpen={openModal}
      handleCloseModal={() => {
        setOpenModal(false);
      }}
      onSecondaryClick={() => {
        setOpenModal(false);
        onYesClick?.();
      }}
      onPrimaryClick={() => {
        setOpenModal(false);
        onNoClick?.();
      }}
    />
  );
}

export default DirtyModal;
