import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { useRouter } from 'next/navigation';

import DirtyModal from '@components/form/dirty-modal';

import { IDirtyCheck, resetDirty } from '../redux/slices/dirty-slice';
import { useAppDispatch, useAppSelector } from '../redux/store';

// Set dirty dialog when user navigates to another page/calls function from an item that outside the dirty form
const useDirtyGlobal = (callbacks?: Record<string, () => void | Promise<void>>, formName?: string) => {
  const router = useRouter();
  const dirtyState: IDirtyCheck = useAppSelector((state) => state.dirty);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [mainAction, setMainAction] = useState<(() => void) | undefined>(undefined);
  const dispatch = useAppDispatch();

  const isDirty = useMemo(() => {
    return _.some(dirtyState, formName ? {value: true, formName} : { value: true });
  }, [dirtyState, formName]);

  // Use as middle check before router.push function is called
  // If the form is dirty, it will open the dirty modal instead
  const customRouter = {
    push: (url: string) => {
      if (isDirty) {
        setOpenModal(true);
        setMainAction(() => {
          return () => {
            dispatch(resetDirty());
            router.push(url);
          };
        });
      } else {
        dispatch(resetDirty());
        router.push(url);
      }
    },
    replace: (url: string) => {
      if (isDirty) {
        setOpenModal(true);
        setMainAction(() => {
          return () => {
            dispatch(resetDirty());
            router.replace(url);
          };
        });
      } else {
        dispatch(resetDirty());
        router.replace(url);
      }
    },
    prefetch: (url: string) => {
      dispatch(resetDirty());
      router.prefetch(url);
    },
    back: () => {
      dispatch(resetDirty());
      router.back();
    },
    forward: () => {
      dispatch(resetDirty());
      router.forward();
    },
    refresh: () => {
      dispatch(resetDirty());
      router.refresh();
    },
  };

  // Use as middle check before callback function is called
  // If the form is dirty, it will open the dirty modal instead
  const checked: { [key: string]: () => void } = callbacks
    ? Object.entries(callbacks).reduce(
        (acc, [key, callback]) => ({
          ...acc,
          [key]: async () => {
            if (!isDirty) {
              await callback();
              return;
            }

            setOpenModal(true);
            setMainAction(() => callback);            
          },
        }),
        {}
      )
    : {};

  useEffect(() => {
    if (!isDirty && openModal) {
      setOpenModal(false);
    }
  }, [isDirty, openModal]);

  const modal = (
    <DirtyModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      onYesClick={() => {
        mainAction?.();
      }}
    />
  );
  return {
    router: customRouter,
    checkedCallback: checked,
    dirtyModal: modal,
    isDirty,
  };
};

export default useDirtyGlobal;
