import { MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';

import Button from '../button';
import Modal from '../modal';
import { sanitizeHtml } from '@helpers/common-helpers';

export type DialogInfoType = {
  title?: string;
  questionMessage?: string;
  detailMessage?: string;
  primaryBtnText?: string
  secondaryBtnText?: string;
};

export type DialogStateType = DialogInfoType & {
  isOpen: boolean;
  onPrimaryClick?: MouseEventHandler<HTMLButtonElement>
  onSecondaryClick?: MouseEventHandler<HTMLButtonElement>
  handleCloseModal: () => void;
  buttonGroups?: ReactNode;
  isLoading?: boolean;
};

const Dialog = ({
  buttonGroups,
  isOpen,
  questionMessage,
  title,
  detailMessage,
  onPrimaryClick,
  onSecondaryClick,
  primaryBtnText,
  secondaryBtnText,
  handleCloseModal,
  isLoading = false
}: DialogStateType) => {
  return (
    <Modal isOpen={isOpen} title={title} handleCloseModal={handleCloseModal} className="sm:!max-w-[585px]" isLoading={isLoading}>
      <div
        className={classNames({
          'mb-[60px]': !detailMessage,
          'mb-[40px]': detailMessage,
        })}
      >
        {questionMessage && (
          <div className="dialog-question-global text-black-title text-sm font-semibold leading-normal break-words line-clamp-3" dangerouslySetInnerHTML={{ __html: sanitizeHtml(questionMessage)}} />
        )}
        {detailMessage && <div className="dialog-detail-global text-black-title text-xs font-light leading-normal break-words line-clamp-3" dangerouslySetInnerHTML={{ __html: sanitizeHtml(detailMessage)}} />}
      </div>
      {buttonGroups ? (
        buttonGroups
      ) : (
        <div className="flex flex-row-reverse gap-x-5">
          <Button
            className={classNames('primary', {
              'w-[106px]': !isLoading,
              'w-[118px]': isLoading
            })}
            size="md"
            onClick={onPrimaryClick}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <div className="text-center text-xs font-bold leading-3 tracking-[0.72px]">{primaryBtnText || 'NO'}</div>
          </Button>
          <Button
            className="outlined-primary w-[106px]"
            size="md"
            onClick={onSecondaryClick}
            disabled={isLoading}
          >
            <div className="text-center text-xs font-bold leading-3 tracking-[0.72px]">{secondaryBtnText || 'YES'}</div>
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default Dialog;
